<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <p class="select-box-title" v-if="type !== 2">
      已选择{{ departNum }}个链群 已选择{{ userNum }}人<span class="delall" @click="del(-1)"
        v-if="(departNum && departNum > 0) || (userNum && userNum > 0)">一键清除</span>
    </p>
    <div class="avator-box" v-for="(item, index) in list" :key="index">
      <img v-if="item.headImg" :src="item.headImg" alt="" />
      <el-avatar v-else> {{ item.username?.slice(-1) }} </el-avatar>
      <p class="name" :title=" item.name ? item.name : item.orgName">{{ item.name ? item.name : item.orgName }}</p>
      <el-icon class="del" :size="16" color="#999" @click="del(index)" v-if="type !== 2">
        <CircleCloseFilled />
      </el-icon>
    </div>
    <div class="avator-box" @click="selectPer" v-if="type !== 2">
      <img src="../assets/img/icon_image_add.png" alt="" />
      <p class="name">添加</p>
    </div>
  </div>
</template>
<script>
import { ElMessageBox, ElMessage } from "element-plus";
import { isIHaier } from '@/utils/platform'
import { getDepartName } from '@/api/ihaier';
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    type: {
      type: Number,
      default: 1,
    },
    maxCount: {
      type: Number,
    },
  },
  data() {
    return {
      userNum: null,
      departNum: null,
      version: 0,
      arr_user: [],
      arr_depart: [],
    };
  },
  created() {
    console.log(this.type, typeof this.type);
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(newList) {
        let alreadyContactIds = newList.map((item) => {
          if (item.type == 1) {
            return item.id;
          }
        });
        let alreadyPlatformIds = newList.map((item) => {
          if (item.type == 2) {
            return item.id;
          }
        });
        let userId = alreadyContactIds.filter((item) => item !== undefined);
        let departId = alreadyPlatformIds.filter((item) => item !== undefined);
        this.userNum = userId.length;
        this.departNum = departId.length;
      },
    },
  },
  mounted() {
    dcm.getVersion({
      onSuccess: (data) => {
        console.log("dcm.selectComponent2 onSuccess", data);
        this.version = this.getStringofNum(data.version);
      },
      onFail: (data) => {
        console.log("dcm.selectComponent2 onFail", data);
      },
    });
  },
  methods: {
    getStringofNum(text) {
      //取出字符串中的数字
      var value = text.replace(/[^0-9]/gi, "");
      return value;
    },

    ihaierSelectComponent() {
      let _this = this;
      let hasList = _this.list.map(item=>{
         return item.ihaierId;
      })
      console.log('传入的 部门和 人的idsss',hasList);
      window.h5sdk.ready(() => {
        window.tt.chooseContact({
          multi: true,
          ignore: false,
          maxNum: 1000,
          enableChooseDepartment: true, // 是否支持部门
          choosenIds: hasList,
          limitTips: '选择联系人数量已超过上限',
          externalContact: false,
          success(res) {
            console.log(`联系人列表`, res);
            const { data, department_data } = res;
            const idlist = department_data.map((item) => {
              return item.openDepartmentId
            })
            let Departmentdata=[];
            getDepartName(idlist).then((res) => {
             if(res){
              Departmentdata= department_data.map((data)=>{
                if(res.data.find((v)=>v.departmentId===data.openDepartmentId)){
                  return {...data, orgName: res.data.find((v)=>v.departmentId===data.openDepartmentId)?.name}
                } else {
                
                  return {...data}
                }
                
              })
             }
            
             console.log(Departmentdata,'Departmentdata')
             _this.$emit('addPer', [...data, ...Departmentdata]);
            })
            
            // 取消焦点
          },
          fail(res) {
            console.log(`获取联系人失败`, res);
          }
        });
      });
    },
    selectComponent(alreadyContacts, alreadyPlatforms) {
      alreadyContacts = alreadyContacts || [];
      alreadyPlatforms = alreadyPlatforms || [];
      return new Promise((resolve, reject) => {
        dcm.selectComponent2({
          appId: window.origin.includes("-test")
            ? "3dyvMf_ebQeYIF2qNXOB2"
            : "HNugSV9C1hKQBjScaq9xO",
          title: "选择联系人",
          limitTips: "已经是最大人数",
          maxCount: this.maxCount, // 最大可选人数 1-500
          alreadyContacts: alreadyContacts, //已选人员
          alreadyPlatforms: alreadyPlatforms, // 已选部门
          forbibContacts: [], // 不可选人员
          mustContacts: [], //必选人员
          forbibPlatforms: [], //不可选部门
          mustPlatforms: [], // 必选部门
          userMark: [],
          onSuccess: (data) => {
            console.log("dcm.selectComponent2 onSuccess", data);

            resolve(data);
          },
          onFail: (data) => {
            console.log("dcm.selectComponent2 onFail", data);
            reject(data);
          },
        });
      });
    },

    selectPer() {
      if (isIHaier) {
        this.ihaierSelectComponent();

        // ElMessage({
        //     message: '请到海尔人app操作',
        //     type: 'warning'
        // });
        return;
      }
      console.log(this.version);
      console.log("调用选人组件", this.list);
      if (this.version > 1060 || this.version == 1060) {
        let alreadyContactIds = this.list.map((item) => {
          if (item.type == 1) {
            return item.id;
          }
        });
        let alreadyPlatformIds = this.list.map((item) => {
          if (item.type == 2) {
            return item.id;
          }
        });
        let userId = alreadyContactIds.filter((item) => item !== undefined);
        let departId = alreadyPlatformIds.filter((item) => item !== undefined);
        this.userNum = userId.length;
        this.departNum = departId.length;
        this.selectComponent(userId, departId).then((res) => {
          if (res.selectedUsers && res.selectedUsers.length > 0) {
            this.arr_user = res.selectedUsers.map((item) => {
              return Object.assign(
                {},
                {
                  id: item.id,
                  name: item.name,
                  username: item.username,
                  headImg: item.headImg,
                  type: 1,
                }
              );
            });
          } else {
            this.arr_user = [];
          }
          if (res.departments && res.departments.length > 0) {
            this.arr_depart = res.departments.map((item) => {
              return Object.assign(
                {},
                {
                  id: item.id,
                  orgName: item.orgName,
                  // name: item.orgName,
                  type: 2,
                  headImg: window.origin.includes("-test")
                    ? "https://managedigital-record-test.oss-cn-beijing.aliyuncs.com/a94fb4cf-0478-4869-88a7-3a5e52c9e91e.png" // 测试
                    : "https://managedigital-record.oss-cn-beijing.aliyuncs.com/mmexport1668563212903.png", // 正式
                }
              );
            });
          } else {
            this.arr_depart = [];
          }
          let data = [...this.arr_user, ...this.arr_depart];
          this.$emit("addPer", data);
          this.$emit("getUsers", data);
        });
      } else {
        ElMessage({
          message: "版本过低，请更新版本",
          type: "warning",
        });
      }
    },
    del(index) {
      if (index == -1) {
        ElMessageBox.alert("确定要清除所有已选用户吗？", "提示", {
          confirmButtonText: "确定",
          customClass: "box",
          confirmButtonClass: "ExitConfirmButton",
          callback: (action) => {
            console.log(action);
            if (action === "confirm") {
              this.$emit("clearPer", "range");
              // this.$emit("getUsers", []);
            }
          },
        });
      } else {
        this.list.splice(index, 1);
        this.$emit("delEvent", this.list);
        // this.$emit("getUsers", this.list);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-box-title {
  font-size: 14px;
  color: #60626c;
  line-height: 20px;
  // padding-bottom: 24px;
}

.delall {
  width: 48px;
  height: 17px;
  font-size: 12px;
  color: #286cfb;
  line-height: 17px;
  margin-left: 16px;
  cursor: pointer;
}

.avator-box {
  float: left;
  width: 50px;
  position: relative;
  // margin-bottom: 10px;
  margin-right: 20px;
  cursor: pointer;

  ::v-deep(.el-avatar) {
    margin: 0 auto;
    display: block;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }

  .del {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.name {
  // width: 36px;
  height: 17px;
  font-size: 12px;
  color: #1f2227;
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
}
</style>
<style>
.ExitConfirmButton {
  border: 1px solid #286cfb !important;
  background: #286cfb !important;
  color: #fff !important;
}

.ExitConfirmButton:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}
</style>
